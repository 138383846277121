import React from "react";
import "./home.scss";
import { Container, Text } from "@chakra-ui/react";
import MainLayout from "../../components/layout/MainLayout";
import IntialComponent from "../../components/initialComponent/IntialComponent";
import AboutMe from "../../components/AboutMe/AboutMe";
import { Waypoint } from "react-waypoint";
const Home = () => {
  return (
    <>
      <MainLayout children={<IntialComponent />} />
    </>
  );
};

export default Home;
