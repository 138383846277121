import React from "react";
import { Text, Button, Image, Divider } from "@chakra-ui/react";
import "./aboutMe.scss";
const AboutMe = () => {
  return (
    <>
      <div>
        <div className="titleWrap">
          <p className="berforeMainNumber">01. </p>
          <Text className="beforeMain1">About Me</Text>
          <span className="horizontal"></span>
        </div>
        <Text className="paragraph">
          I’m a software engineer specializing in building web and mobile
          applications. Currently, I’m focused on building accessible,
          human-centered products.
        </Text>
        <Text className="paragraph">
          Hello! My name is Milot. and I enjoy creating things that live on the
          internet. My interest in web development started back in 2020 when I
          decided to try editing custom Tumblr themes — turns out hacking
          together a custom reblog button taught me a lot about HTML & CSS!
          Fast-forward to today, and I’ve had the privilege of working at an
          advertising agency, a start-up, a huge corporation, and a student-led
          design studio. My main focus these days is building accessible,
          inclusive products and digital experiences at Upstatement for a
          variety of clients. I also recently launched a course that covers
          everything you need to build a web app with the Spotify API using Node
          & React. Here are a few technologies I’ve been working with recently:
        </Text>
      </div>
    </>
  );
};

export default AboutMe;
