import React from "react";
import { Text, Button, Image, Divider } from "@chakra-ui/react";
import "./initialComponent.scss";
import AboutMe from "../AboutMe/AboutMe";
const IntialComponent = () => {
  return (
    <div className="wrapper">
      <div className="firstPart">
        <Text className="beforeMain">Hi,my Name is</Text>
        <Text className="main">Milot Hajredini</Text>
        <Text className="afterMain">I build Web & Mobile Apps</Text>
        <Text className="paragraph">
          I’m a software engineer specializing in building web and mobile
          applications. Currently, I’m focused on building accessible,
          human-centered products.
        </Text>

        <Button className="btn btn-primary checkMeButton">Check out!</Button>
      </div>
      <div className="secondPart">
        <AboutMe />
      </div>
    </div>
  );
};

export default IntialComponent;
