import React, { Fragment, useEffect, useState } from "react";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import { Button, Image, Text } from "@chakra-ui/react";
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <div className="nav-wrapper">
      {/* <Link to="/"> */}
      <div className="logo-container">{/* <Logo /> */}</div>
      {/* </Link> */}
      <div className={`links-container ${navbarOpen ? "opened" : "hidden"}`}>
        <p className="linkNumber m-0">01.</p>
        <p className="linkNames">About</p>
        <p className="linkNumber">02.</p>
        <p className="linkNames">Experience</p>
        <p className="linkNumber">03.</p>
        <p className="linkNames">Work</p>
        <p className="linkNumber">04.</p>
        <p className="linkNames">Contact</p>
        <Button className="btn btn-primary resumeBtn">Resume</Button>
        {/* </Link> */}
      </div>
      <div
        className={`burger ${navbarOpen ? "toggled" : ""}`}
        onClick={() => setNavbarOpen((prev) => !prev)}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Navbar;
