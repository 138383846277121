import * as React from "react";

const Twitter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="30px"
    viewBox="0 0 50 50"
    style={{
      fill: "#fff",
      marginBottom: "40%",
    }}
  >
    <path d="M34.219 5.469c-5.98 0-10.844 4.863-10.844 10.843 0 .36.09.711.125 1.063-7.395-.707-13.934-4.27-18.375-9.719a1.005 1.005 0 0 0-.863-.379.995.995 0 0 0-.793.504A10.758 10.758 0 0 0 2 13.22c0 2.402.86 4.601 2.188 6.406-.258-.113-.54-.176-.782-.313a.997.997 0 0 0-1.469.875v.125c0 3.684 1.907 6.883 4.72 8.844-.032-.004-.063.008-.095 0a1 1 0 0 0-1.125 1.282c1.118 3.472 3.97 6.125 7.5 7.093-2.812 1.672-6.074 2.657-9.593 2.657-.762 0-1.492-.04-2.219-.126a.998.998 0 0 0-1.074.657.997.997 0 0 0 .418 1.187A28.84 28.84 0 0 0 16.094 46.5c9.332 0 16.652-3.898 21.562-9.469 4.91-5.57 7.469-12.804 7.469-19.562 0-.285-.023-.563-.031-.844 1.832-1.402 3.468-3.047 4.75-4.969a.998.998 0 0 0-.067-1.21.996.996 0 0 0-1.183-.258c-.516.23-1.117.253-1.657.437.711-.95 1.32-1.973 1.688-3.125a1.005 1.005 0 0 0-.336-1.086 1.002 1.002 0 0 0-1.133-.07C45.45 7.355 43.56 8.066 41.563 8.5c-1.938-1.813-4.489-3.031-7.344-3.031Zm0 2c2.55 0 4.855 1.09 6.468 2.812.243.25.598.356.938.281a20.581 20.581 0 0 0 3.75-1.124 8.903 8.903 0 0 1-2.563 2.343.998.998 0 0 0-.503 1.23.998.998 0 0 0 1.16.645c1-.12 1.89-.527 2.843-.781a18.992 18.992 0 0 1-2.812 2.5.997.997 0 0 0-.406.875c.015.406.031.809.031 1.219 0 6.25-2.398 13.035-6.969 18.218-4.57 5.184-11.281 8.813-20.062 8.813-3.989 0-7.754-.883-11.157-2.438 4.22-.324 8.11-1.816 11.25-4.28A.999.999 0 0 0 15.594 36c-3.296-.06-6.058-1.978-7.53-4.688h.156c.988 0 1.965-.125 2.875-.375.437-.13.738-.536.722-.993a1.004 1.004 0 0 0-.785-.945c-3.558-.719-6.226-3.617-6.844-7.219 1.008.348 2.04.621 3.157.657a1 1 0 0 0 .593-1.844A8.855 8.855 0 0 1 4 13.219c0-1.14.297-2.188.688-3.188 5 5.489 12 9.133 19.906 9.531.312.016.617-.113.82-.351a1 1 0 0 0 .211-.867 8.925 8.925 0 0 1-.25-2.032 8.826 8.826 0 0 1 8.844-8.843Z" />
  </svg>
);

export default Twitter;
