import React, { Children } from "react";
import Navbar from "../Navbar/Navbar";
import { Button, Image, Text, Icon, Container } from "@chakra-ui/react";
import "./mainLayout.scss";
import Git from "../../assets/icons/Git";
import Instagram from "../../assets/icons/Instagram";
import Twitter from "../../assets/icons/Twitter";
import LinkedIn from "../../assets/icons/LinkedIn";

const MainLayout = ({ children }) => {
  return (
    <>
      <div className="social">
        <Git />
        <Instagram />
        <Twitter />
        <LinkedIn />
        <span className="horizontalLine"></span>
      </div>
      <div>{children}</div>
    </>
  );
};

export default MainLayout;
