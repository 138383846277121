import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Home from "./views/Home/Home";
import { Outlet } from "react-router-dom";
const App = () => {
  return (
    <div>
      <Home />
      <Outlet />
    </div>
  );
};

export default App;
